<template>
  <div class="userCollect">
    <div class="userNewsLeft">
      <ul>
        <li :class="{ active: tabindex == 1 }" @click="clickActive(1)">
          全部作业
        </li>
        <li :class="{ active: tabindex == 2 }" @click="clickActive(2)">
          优秀作业
        </li>
        <!-- <li :class="{ active: tabindex == 3}" v-if="is_benren == 1" @click="clickActive(3)">
					奖品列表 
				</li> -->
      </ul>
    </div>
    <!-- 作业记录 -->
    <div class="list userNewsRight" v-if="tabindex == 1 || tabindex == 2">
      <div class="video_list clearfix">
        <div
          class="fl video_li"
          :style="{ animationDelay: 0.1 * index + 's' }"
          v-for="(item, index) in worklist"
          :key="index"
          @click="toVideoDetail(item.video_id, item.id)"
        >
          <div class="video_li_box">
            <div class="reading_thumb">
              <img v-lazy="item.thumb" :key="item.thumb" alt="" />
            </div>
          </div>
          <div class="video_li_reading clearfix">
            <div class="fl">
              <div class="video_li_name">{{ item.content }}</div>
            </div>
          </div>
          <div class="deletes" v-if="is_benren==1" @click.stop="deletes(item.id)">删除</div>
          <div v-if="item.is_activity==1" :class="'goods goods'+item.activity_ranking"></div>
        </div>
      </div>
      <el-empty
        v-if="!worklist || worklist.length <= 0"
        description="暂无数据"
      ></el-empty>
      <page v-if="total > 12" :page="page" :limit="limit" :total="total"></page>
    </div>

    <!-- 奖品列表 -->
    <div class="list userNewsRight" v-if="tabindex == 3">
      <div class="video_list clearfix">
        <div>
          <ul class="userOrder_table">
            <li>标题</li>
            <li>姓名</li>
            <li>电话</li>
            <li>地址</li>
            <li>创建时间</li>
            <li>操作</li>
            <li>快递单号</li>
          </ul>
          <div
            class="userNewsRight_one"
            :style="{ animationDelay: 0.1 * index + 's' }"
            v-for="(item, index) in Recordlist"
            :key="index"
          >
            <div class="news_right">
              <!-- 编号 -->
              <div class="order_content">
                <p>
                  {{ item.title }}
                </p>
              </div>
              <!-- 名称 -->
              <div class="order_content">
                {{ item.name ? item.name : "--" }}
              </div>
              <!-- 金额 -->
              <div class="order_content">
                {{ item.mobile ? item.mobile : "--" }}
              </div>
              <!-- 时间 -->
              <div class="order_content">
                {{ item.address ? item.address : "--" }}
              </div>
              <!-- 操作 -->
              <div class="order_content">
                {{ item.create_time }}
              </div>
              <div class="order_content">
                <div class="looks" v-if="item.is_add">--</div>
                <div class="write" v-else @click="writeFun(item.id)">
                  {{ item.is_add == 1 ? "--" : "填写" }}
                </div>
              </div>
              <!-- 快递信息 -->
              <div class="order_content">
                {{ item.express ? item.express : "暂无快递信息" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-empty
        v-if="!Recordlist || Recordlist.length <= 0"
        description="暂无数据"
      ></el-empty>
      <page v-if="total > 12" :page="page" :limit="limit" :total="total"></page>
    </div>

    <el-drawer
      :visible.sync="isprize"
      direction="ttb"
      :with-header="false"
      :destroy-on-close="true"
    >
      <prize :pid="pid" @close="closeDrawer"></prize>
    </el-drawer>
  </div>
</template>

<script>
// import { WOW } from 'wowjs'
import page from "../../components/page.vue";
import prize from "../../components/prizePopup.vue";
export default {
  name: "userCollect",
  components: {
    page,
    prize,
  },
  data() {
    return {
      // 全部作业
      worklist: [],
      // 奖品列表
      Recordlist: [],

      // 分页
      total: 0, //总数
      page: 1, //页数
      limit: 12, //每页个数

      // 收藏参数
      type: "", //类型
      collId: "", //收藏id

      is_benren: 0,

      tabindex: 1,

      isprize: false,
      pid: "", //奖品id
    };
  },
  mounted() {
    this.$parent.tab = 2;
    this.$parent.tabIndex = 7;
    this.$parent.$parent.routerIndex = 0;
    this.$parent.$parent.showIndex = 1;
    if (this.$route.query.type) {
      this.tabindex = this.$route.query.type;
    }
	console.log('1111111',document.domain);
    if (this.tabindex == 3) {
      this.RecordFun();
    } else {
      this.workFun(1);
    }
    this.getUser();
  },
  methods: {
    //获取用户信息
    getUser() {
      var that = this;
      this.$api.POST(
        this.$face.memberInfo,
        {
          user_id: this.$route.query.userId,
        },
        function (res) {
          that.is_benren = res.data.is_benren;
          console.log(that.is_benren);
          // localStorage.setItem('is_benren', res.data.is_benren);
          // if (res.data.is_benren != 1) {
          // 	localStorage.setItem('userInfo_other', JSON.stringify(res.data))
          // }
        }
      );
    },
    listFun() {
      if (this.tabindex == 1 || this.tabindex == 2) {
        this.workFun(this.tabindex);
      } else if (this.tabindex == 3) {
        this.RecordFun();
      }
    },
    // 作业记录
    workFun(type) {
      var that = this;
      this.$api.POST(
        this.$face.videTask,
        {
          user_id: this.$route.query.userId || "",
          page: this.page,
          limit: this.limit,
          type: type,
        },
        function (res) {
          console.log(res);
          that.worklist = res.data.list;
          that.total = res.data.total;
        }
      );
    },
    deletes(id) {
      this.$confirm("此操作将删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.POST(this.$face.videoTaskdel, { id: id }, (res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.listFun();
          }
        });
      });
    },
    // 奖品列表
    RecordFun() {
      var that = this;
      this.$api.POST(
        this.$face.good_rewardRecord,
        {
          user_id: this.$route.query.userId || "",
          page: this.page,
          limit: this.limit,
        },
        function (res) {
          console.log(res);
          that.Recordlist = res.data.lists;

          that.total = res.data.total;
        }
      );
    },
	toVideoDetail(id,task_id){
      let routeData = this.$router.resolve({
        name: "videoDetail",
        query: {
          id: id,
          task_id:task_id
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 左侧导航选中
    clickActive(type) {
      console.log("type", type);
      this.tabindex = type;
      this.page = 1;
      if (type == 1 || type == 2) {
        this.workFun(type);
      } else if (type == 3) {
        this.RecordFun();
      }
    },
    closeDrawer(i) {
      this.isprize = false;
      if (i == 1) {
        this.RecordFun();
      }
    },
    writeFun(id) {
      this.isprize = true;
      this.pid = id;
    },
  },
};
</script>

<style scoped>
.userNewsRight_one {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f5f5;
}

.order_content {
  width: 195px;
  height: 100%;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  /* overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap; */
}
.order_content div {
  cursor: pointer;
}

.order_content:nth-child(3),
.userOrder_table li:nth-child(3) {
  width: 100px;
}
.order_content:nth-child(4),
.userOrder_table li:nth-child(4) {
  width: 150px;
}
.news_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0;
  box-sizing: border-box;
}
.order_content:nth-child(2),
.userOrder_table li:nth-child(2) {
  width: 60px;
}
.order_content:nth-child(5),
.userOrder_table li:nth-child(5) {
  width: 150px;
}
.order_content:nth-child(6),
.userOrder_table li:nth-child(6) {
  width: 50px;
}
.userOrder_table {
  width: 100%;
  height: 60px;
  background: #f4f6f6;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  margin-top: 25px;
}

.userOrder_table li {
  width: 195px;
  font-size: 14px;
  color: #333333;
  line-height: 60px;
}

.userOrder_table_two li {
  padding-left: 30px;
}

.userNewsLeft {
  background-color: #fff;
  border-radius: 10px;
  width: 15%;
  float: left;
}

.userNewsLeft ul {
  padding: 37px 0 0;
  overflow: hidden;
}

.userNewsLeft li {
  font-size: 16px;
  color: #333;
  padding-left: 37px;
  margin-bottom: 37px;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  position: relative;
}

.userNewsLeft li::before {
  display: block;
  width: 6px;
  height: 20px;
  content: " ";
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
}

.userNewsLeft li.active::before {
  background-color: #5957ff;
}

.userNewsLeft li.active {
  color: #5957ff;
}

.userNewsRight {
  width: 78%;
  background: #fff;
  border-radius: 10px;
  float: right;
  padding: 15px 2% 0;
  min-height: 500px;
  position: relative;
}

.userCollect {
  padding-top: 26px;
  height: auto;
  overflow: hidden;
}

.list {
  padding-bottom: 99px;
}

/* 视频教程 */
.video_list {
  margin: 0 -13.33px 24px;
}

.video_li {
  width: 330px;
  margin: 0 13.33px 24px;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.video_li:hover .deletes {
  z-index: 1;
  opacity: 1;
}
.video_li_box {
  width: 100%;
  height: 198px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.video_li_box > img {
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.video_li_mask {
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  /* opacity: 0; */
  z-index: 33;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: default;
}

.video_li_play {
  position: absolute;
  top: 140%;
  left: 50%;
  z-index: 101;
  margin-left: -37.5px;
  margin-top: -37px;
  width: 74px;
  height: 75px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.video_li:hover .video_li_mask {
  height: 100%;
}

.video_li:hover .video_li_play {
  top: 50%;
  transition-delay: 0.3s;
}

/* .video_li:hover .video_li_box img{
    transform: scale(1.08)
} */

.video_li_name {
  /* height: 52px;
    line-height: 49px; */
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

.video_li_reading {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  color: #999999;
  min-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.video_li_reading img {
  width: auto;
  height: 20px;
  margin-right: 4px;
}

.video_li_reading span {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #999999;
}

.video_li_reading_k {
  margin-right: 16px;
}

.userFavorite_li_del {
  width: 43px;
  height: 26px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 15px;
  right: 16px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.video_li:hover .userFavorite_li_del {
  opacity: 1;
}

.video_li .video_li_box {
  height: 198px;
}

.video_li .reading_thumb {
  position: relative;
  height: 198px;
  transition: all 0.3s ease-in-out;
  background-color:#eee;
}

.video_li .reading_thumb > img {
 
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: auto;
  height: 100%;
}

.video_li:hover .reading_thumb {
  transform: scale(1.08);
}
.write {
  color: #5957ff;
}
.userCollect >>> .el-drawer {
  height: 100% !important;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0px rgb(0 0 0 / 0%),
    0 0px 0px 0px rgb(0 0 0 / 0%) !important;
}
.deletes {
  width: 43px;
  height: 26px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 25px;
  right: 25px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  opacity: 0;
  z-index: -1;
}
.goods {
  position: absolute;
  right: 13px;
  top: 13px;
  width: 86px;
  height: 86px;
  background-size: 86px;
  background-repeat: no-repeat;
}
.goods3 {
  background-image: url("../../assets/images/good_one.png");
}
.goods2 {
  background-image: url("../../assets/images/good_two.png");
}
.goods1 {
  background-image: url("../../assets/images/good_three.png");
}
</style>
